<template>
  <section class="gallery part">
    <div v-view class="constrain animate in-bottom">
      <div class="row-12">
        <div class="col-12 md:col-8">
          <slider
            ref="sliderRef"
            v-slot="{ item }"
            class="slider"
            :items="payload.bilder"
            :slides-per-view="{ desktop: 1, mobile: 1 }"
            :slides-per-group="{ desktop: 1, mobile: 1 }"
            @progress="onProgress"
            @afterInit="updatePage"
            @slideChange="updatePage"
            @resize="updatePage"
          >
            <box force-aspect-ratio :aspect="0.5">
              <Image class="image" :src="item" />
            </box>
          </slider>
          <page-progress
            class="progress"
            :start="start"
            :end="end"
            :current-page="page"
            :pages="pages"
            @prev="prev"
            @next="next"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref } from 'vue';
import { useVisibleProgress, usePage } from '@/composables/swiper';

export default {
  props: { payload: { type: Object, default: () => ({}) } },
  setup(props) {
    const sliderRef = ref(null);

    const { onProgress, start, end } = useVisibleProgress();
    const { updatePage, page, pages } = usePage();

    const prev = () => sliderRef.value?.prev();
    const next = () => sliderRef.value?.next();

    return {
      sliderRef,
      page,
      pages,
      start,
      end,
      prev,
      next,
      onProgress,
      updatePage,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_mixin.scss";

.gallery {
  @include responsive('margin-top', px(64), px(190));

  &:last-of-type {
    @include responsive('margin-bottom', px(80), px(210));
  }
}

.slider {
  @include responsive('overflow', visible, hidden);
}

:deep(.image) {
  @apply rounded-big;
  object-fit: cover;
}

.progress {
  @include responsive('margin-top', px(14), px(38))
}
</style>
